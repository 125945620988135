import styled from "styled-components";
import { Breakpoints, Colors, Fonts, FontSizes } from "styles/variables";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > p {
    width: 100%;
  }

  > p span {
    font-size: ${FontSizes.xmd};
    line-height: 40px;
    word-wrap: break-word;

    > h1 {
      font-family: ${Fonts.poppins};
      font-size: ${FontSizes.xxlg};
      font-weight: 800;
      line-height: 73px;
    }

    > h2 {
      font-size: ${FontSizes.xmd};
      line-height: 32px;
      font-weight: 700;
    }
  }

  @media screen and (min-width: ${Breakpoints.desktop}) {
    padding: 200px 128px 172px 164px;
  }

  @media screen and (max-width: ${Breakpoints.desktop}) {
    padding: 104px 30px 30px;

    > p span {
      font-size: ${FontSizes.med};
      line-height: 32px;

      > h1 {
        font-size: ${FontSizes.lg};
        line-height: 36px;
      }

      > h2 {
        font-size: ${FontSizes.med};
      }
    }
  }
`;

export const Title = styled.p`
  color: ${Colors.cnbar};
  font-family: ${Fonts.poppins};
  line-height: 73px;
  font-size: ${FontSizes.xxlg};
  font-weight: 700;
  margin-bottom: 98px;
`;
