import styled from "styled-components";
import { Colors } from "styles/variables";

export const Button = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 4px;
  background: ${Colors.cnbar};
  position: fixed;
  right: 25px;
  bottom: 25px;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    color: #ffffff;
  }
`;
