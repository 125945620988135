import React from "react";
import { BrowserRouter } from "react-router-dom";
import GlobalStyles from "styles/global";
import AppRoutes from "routes";

const app: React.FC = () => {
  return (
    <BrowserRouter>
      <AppRoutes />
      <GlobalStyles />
    </BrowserRouter>
  );
};

export default app;
