import React from "react";
import { Icon } from "@iconify/react";
import { scrollToTop } from "helpers/utils";
import { Button } from "./styles";

const ScrollToTopBtn: React.FC = () => {
  return (
    <Button onClick={scrollToTop}>
      <Icon className="icon" icon="akar-icons:arrow-up" />
    </Button>
  );
};

export default ScrollToTopBtn;
