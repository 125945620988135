/**
 * Colors
 *
 * @param white #FFF8F2
 * @param black #000000
 * @param scndBlack #282828
 * @param cnbarLight #FEEBDD
 * @param cnbarMed #FD937F
 * @param cnbarStrong #FF9A7A
 * @param cnbar #FB6255
 * @param red #FF3632
 */

export const Colors = {
  white: "#FFF8F2",
  black: "#000000",
  scndBlack: "#282828",
  cnbarLight: "#FEEBDD",
  cnbarMed: "#FD937F",
  cnbarStrong: "#FF9A7A",
  cnbar: "#FB6255",
  red: "#FF3632",
};

/**
 * Icons
 *
 * @param menu hamburger
 * @param close close-icon
 * @param linkedin
 * @param facebook
 * @param instagram
 */

export const Icons = {
  menu: "feather:menu",
  close: "feather:x-circle",
  linkedin: "entypo-social:linkedin-with-circle",
  facebook: "entypo-social:facebook-with-circle",
  instagram: "entypo-social:instagram-with-circle",
};

/**
 * Font sizes
 *
 * @param xxlg 3.052rem | 48.83px
 * @param xlg 2.441rem | 39.06px
 * @param lg 1.953rem | 31.25pxpx
 * @param xmd 1.563rem | 25px
 * @param med 1.25rem | 20px
 * @param rg 1rem | 16px
 * @param sm 0.8rem | 12.8px
 * @param xsm 0.64rem | 10.24px
 * @param xxsm 0.512rem | 8.19px
 */

export const FontSizes = {
  xxlg: "3.052rem",
  xlg: "2.441rem",
  lg: "1.953rem",
  xmd: "1.563rem",
  med: "1.25rem",
  rg: "1rem",
  sm: "0.8rem",
  xsm: "0.64rem",
  xxsm: "0.512rem",
};

/**
 * Fonts
 *
 * @param inter Inter, sans-serif
 * @param poppins Poppins, sans-serif
 */

export const Fonts = {
  inter: "Inter, sans-serif",
  poppins: "Poppins, sans-serif",
};

/**
 * Font sizes
 *
 * @param lg_desktop 1440px
 * @param desktop 1024px
 * @param ipad 768px
 * @param mobile 480px
 * @param sm_mobile 320px
 */

export const Breakpoints = {
  lg_desktop: "1440px",
  desktop: "1024px",
  ipad: "834px",
  mobile: "480px",
  sm_mobile: "320px",
};
