/**
 * randomIntFromInterval
 *
 * Returns a random number between two other numbers assigned to 'min' and 'max'
 *
 * @param {number} min number
 * @param {number} max number
 * @returns number
 */

export function randomIntFromInterval(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

/**
* scrollToTop

* Scroll to the top of the page
*/

export function scrollToTop(): void {
  window.scrollTo(0, 0);
}
