import React, { useEffect, useState } from "react";
import { Icons } from "styles/variables";
import { Icon } from "@iconify/react";
import Logo from "assets/svg/logo_full.svg";
import LogoWhite from "assets/svg/logo_full_white.svg";
import useScrollDirection from "../../hooks/scrollDirection";

import { Nav, NavItem, ContactBtn, NavItems, Container } from "./styles";

const Navbar: React.FC<{ page?: string }> = ({ page }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [stickNavbar, setStickNavbar] = useState(false);
  const scrollDirection = useScrollDirection();
  const [aboutHref, setAboutHref] = useState("#about");
  const [letsTalkHref, setLetsTalkHref] = useState("#talk");

  useEffect(() => {
    if (page === "terms" || page === "privacy" || page === "sign-in") {
      setAboutHref("/#about");
      setLetsTalkHref("/#talk");
    }
  }, [aboutHref, letsTalkHref, page]);

  useEffect(() => {
    if (scrollDirection && scrollDirection === "up" && window.scrollY !== 0) {
      setStickNavbar(true);
    } else {
      setStickNavbar(false);
    }
  }, [scrollDirection]);

  return (
    <Container page={`${page}`} scrolled={stickNavbar}>
      <Nav isOpen={isOpen} page={`${page}`} scrolled={stickNavbar}>
        <div className="nav-header">
          <a href="/">
            <img
              className="nav-logo"
              src={
                isOpen || page === "sign-in" || page === "terms"
                  ? Logo
                  : LogoWhite
              }
              alt="Logo"
            />
          </a>

          {isOpen ? (
            <button
              className="menu-bars"
              type="button"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <Icon
                className="icon"
                height={20}
                width={20}
                icon={`${Icons.close}`}
              />
            </button>
          ) : (
            <button
              className="menu-bars"
              type="button"
              onClick={() => {
                setIsOpen(true);
              }}
            >
              <Icon
                className="icon"
                height={24}
                width={24}
                icon={`${Icons.menu}`}
              />
            </button>
          )}
        </div>

        <NavItems className="items">
          <NavItem
            page={`${page}`}
            scrolled={stickNavbar}
            className="nav-item"
            onClick={() => setIsOpen(false)}
            href={page === "home" ? "#" : "/"}
          >
            Home
          </NavItem>
          <NavItem
            page={`${page}`}
            scrolled={stickNavbar}
            className="nav-item"
            onClick={() => setIsOpen(false)}
            href={`${aboutHref}`}
          >
            About
          </NavItem>
          <ContactBtn
            page={`${page}`}
            scrolled={stickNavbar}
            onClick={() => setIsOpen(false)}
            href={`${letsTalkHref}`}
          >
            Let&apos;s talk
          </ContactBtn>
        </NavItems>
      </Nav>
    </Container>
  );
};

export default Navbar;
