import styled, { css } from "styled-components";
import { Colors } from "styles/variables";
import Tooltip from "../Tootip";

interface Focused {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  isModal: boolean;
}

export const Container = styled.div<Focused>`
  background: #ffffff;
  color: #232129;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid #c4c4c4;
  height: 50px;
  display: flex;
  width: 300px;
  align-items: center;
  margin: 0px auto;
  + div {
    border-radius: 0px 0px 0px 0px;
  }
  ${(props) =>
    props.isModal &&
    css`
      margin: 0px;
      background: #ffffff;
      width: 267px;
      color: #a9a9a9;
      border-radius: 4px;
      padding: 16px;
      align-items: center;
    `}
  ${(props) =>
    props.isErrored &&
    css`
      border-color: ${Colors.red};
    `}
  ${(props) =>
    props.isFocused &&
    css`
      color: ${Colors.black};
      border-color: ${Colors.black};
    `}
  ${(props) =>
    props.isFilled &&
    css`
      color: ${Colors.black};
    `}
    ${(props) =>
    props.isErrored &&
    props.isModal &&
    css`
      border-color: ${Colors.red};
    `}
  ${(props) =>
    props.isFocused &&
    props.isModal &&
    css`
      color: ${Colors.cnbar};
      border-color: ${Colors.cnbar};
    `}
  ${(props) =>
    props.isFilled &&
    props.isModal &&
    css`
      color: ${Colors.cnbar};
    `}
  > svg {
    margin-right: 10px;
  }

  input {
    flex: 1;
    background: transparent;
    border: 0;
    color: #232129;
    &::placeholder {
      color: #232129;
    }
  }
`;
export const Error = styled(Tooltip)`
  height: 20px;
  display: block;
  max-width: 50px;

  span {
    background: ${Colors.red};
    color: #fff;
    &::before {
      border-color: ${Colors.red} transparent;
    }
  }
`;
