import React from "react";
import Logo from "assets/svg/logo.svg";
import { Icons } from "styles/variables";
import { Icon } from "@iconify/react";
import { scrollToTop } from "helpers/utils";
import { Container } from "./styles";

const Footer: React.FC<{ page?: string }> = ({ page }) => {
  return (
    <Container id="footer" page={`${page}`}>
      <div className="icons">
        <a
          target="_blank"
          rel="noreferrer"
          className="icon-container"
          href="https://bit.ly/3yb7jur"
        >
          <Icon
            className="icon"
            icon={`${Icons.linkedin}`}
            style={{ color: "#FEB199" }}
          />
        </a>

        <a
          target="_blank"
          rel="noreferrer"
          className="icon-container"
          href="https://bit.ly/3oxqUlr"
        >
          <Icon
            className="icon"
            icon={`${Icons.instagram}`}
            style={{ color: "#FEB199" }}
          />
        </a>

        <a
          target="_blank"
          rel="noreferrer"
          className="icon-container"
          href="https://bit.ly/33e36eh"
        >
          <Icon
            className="icon"
            icon={`${Icons.facebook}`}
            style={{ color: "#FEB199" }}
          />
        </a>
      </div>

      <div className="terms-privacy">
        <a onClick={scrollToTop} href="/terms">
          Terms
        </a>
        |
        <a onClick={scrollToTop} href="/privacy">
          Privacy Policy
        </a>
      </div>

      <div className="info">
        <a href="/" onClick={scrollToTop}>
          <img src={Logo} alt="Logo" />
          <p>Minimap | 2021</p>
        </a>
      </div>
    </Container>
  );
};

export default Footer;
