import React from "react";
import { Icon } from "@iconify/react";
import { Load } from "./styles";

const Loading: React.FC = () => {
  return (
    <Load>
      <Icon
        className="icon"
        height={128}
        icon="ant-design:loading-3-quarters-outlined"
        style={{ color: "#fb6255" }}
      />
    </Load>
  );
};

export default Loading;
