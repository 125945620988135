import { createGlobalStyle } from "styled-components";

import { Breakpoints, Colors, Fonts } from "styles/variables";

export default createGlobalStyle`
  :root {
    color-scheme: light only;
  }

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    background: ${Colors.white};
    color: ${Colors.black};
    -webkit-font-smoothing: antialiased;
    min-height: 100vh;
    overflow-x: hidden;
    font-size: 16px;

    @media screen and (min-width: ${Breakpoints.ipad}) {
      background: #ffffff;
    }
  }

  body,input,button {
    font-family: ${Fonts.inter};
    font-weight: 500;
  }

  h1,h2,h3,h4,h5,h6, strong {
    font-weight:500;
  }

  button, input[type="submit"]{
    cursor:pointer;
    border: none;
    box-shadow: none;
    background: none;
  }

  input,
  textarea,
  button,
  select,
  a,
  Link {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    text-decoration: none;
    color: ${Colors.black};
  }

`;
