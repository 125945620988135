import React from "react";

export const PrivacyText: React.FC = () => {
  return (
    <p>
      <span style={{ whiteSpace: "pre-wrap" }}>
        Effective Date: October 15, 2020.
        <br />
        <br />
        <h1>1. Introduction and Overview.</h1>
        <br />
        <br />
        Welcome to the Privacy Policy for Rad.r, Inc. d/b/a MiniMap
        (&ldquo;MiniMap,&rdquo; &ldquo;we,&rdquo; &ldquo;our,&rdquo; or
        &ldquo;us&rdquo;). MiniMap is a geo-location based social discovery
        network, and this Privacy Policy provides a comprehensive description of
        how we collect, use, and share information about you, as well as your
        rights and choices regarding such information. This Privacy Policy
        applies to any app or online location that links to this Privacy Policy
        (the &ldquo;Service&rdquo;) or offline location that makes this Privacy
        Policy available to you.
        <br />
        <br />
        By using the Service or interacting with us offline, you agree to our
        Terms of Use and to our collection, use and disclosure practices, and
        other activities as described in this Privacy Policy. If you do not
        agree, discontinue use of the Service.
        <br />
        <br />
        If you have any questions or wish to exercise your rights and choices,
        please contact us as set out in the &ldquo;Contact Us&rdquo; section. If
        you are a Nevada or California resident, please see the additional
        disclosures at the end of this Privacy Policy.
        <br />
        <br />
        <h1>2. Information Collection.</h1>
        <br />
        <br />
        <h2>A. Information You Provide.</h2>
        <br />
        <br />
        We collect information about you when you use the Service or interact
        with us offline, including information you provide when you register an
        account, update your profile, access our content, create an or
        participate in an event, contact customer support, or apply for a job.
        The categories of information we collect include:
        <br />
        <br />
        &middot; Contact Data, including your first and last name, email
        address, postal address, and phone number.
        <br />
        <br />
        &middot; Account Credentials, including your username, password,
        password hints, and information for authentication and account access.
        <br />
        <br />
        &middot; Demographic Data, including your age.
        <br />
        <br />
        &middot; Profile Data, including your interests, inferences,
        preferences, activity status, and favorites.
        <br />
        <br />
        &middot; Content, including content within any content you send to us
        (such as feedback, suggestions, and questions to customer support) or
        publicly post on the Service (such as photos, polls, videos/stories, or
        event info). We also collect content within any messages you exchange
        with other users through the Service (such as if you use our chat or
        polling functionality).
        <br />
        <br />
        You may choose to voluntarily provide other information to us that we do
        not request, and, in such instances, you are solely responsible for such
        information.
        <br />
        <br />
        <h2>B. Information Collected Automatically.</h2>
        <br />
        <br />
        In addition, we automatically collect information when you use the
        Service. The categories of information we automatically collect include:
        <br />
        <br />
        Service Use Data, including data about features you use, events or
        profiles you interact with, emails/push notifications and advertisements
        you view, products and services you view and purchase from in-network
        vendors, the time of day you browse, and your referring and exiting
        events.
        <br />
        <br />
        Device Data, including data about the type of device or browser you use,
        your device&rsquo;s operating software, your internet service provider,
        your device&rsquo;s regional and language settings, and device
        identifiers such as IP address and Ad Id.
        <br />
        <br />
        Location Data, including imprecise location data (such as location
        derived from an IP address or data that indicates a city or postal code
        level) and, with your consent, precise location data (such as
        latitude/longitude data).
        <br />
        <br />
        The types of tracking technologies we use to automatically collect
        information include:
        <br />
        <br />
        &middot; Log Files, which are files that record events that occur in
        connection with your use of the Service.
        <br />
        <br />
        &middot; Cookies, which are small data files stored on your device that
        act as a unique tag to identify your browser. We use two types of
        cookies: session cookies and persistent cookies. Session cookies make it
        easier for you to navigate our website and expire when you close your
        browser. Persistent cookies help with personalizing your experience,
        remembering your preferences, and supporting security features.
        Additionally, persistent cookies allow us to bring you advertising both
        on and off the Service. Persistent cookies may remain on your device for
        extended periods of time, and generally may be controlled through your
        browser settings.
        <br />
        <br />
        &middot; Pixels (also known as web beacons), which is code embedded in a
        website, video, email, or advertisement that sends information about
        your use to a server. There are various types of pixels, including image
        pixels (which are small graphic images) and JavaScript pixels (which
        contains JavaScript code). When you access a website, video, email, or
        advertisement that contains a pixel, the pixel may permit us or a
        separate entity to drop or read cookies on your browser. Pixels are used
        in combination with cookies to track activity by a particular browser on
        a particular device. We may incorporate pixels from separate entities,
        including Facebook, that allow us to track our conversions, bring you
        advertising both on and off the Service, and provide you with additional
        functionality, such as the ability to connect our Service with your
        social media account.
        <br />
        <br />
        App Technologies, which are technologies included in our apps that are
        not browser-based like cookies and cannot be controlled by browser
        settings. For example, our apps include SDKs, which is code that sends
        information about your use to a server, for instance through Facebook or
        Google SDKs. These SDKs allow us to track your conversions, bring you
        advertising both on and off the Service, and provide you with additional
        functionality, such as the ability to connect our Service with your
        social media account.
        <br />
        <br />
        &middot; Location-Identifying Technologies, which are technologies used
        to collect your location. For example, GPS, WiFi, and Bluetooth may be
        used to collect precise location data when you consent to precise
        location tracking through our apps. Location data may be used for
        purposes such as verifying your device&rsquo;s location and delivering
        or restricting relevant content and advertising based on that location.
        <br />
        <br />
        For further information on how we use tracking technologies for
        analytics and advertising, and your rights and choices regarding them,
        see the &ldquo;Analytics and Advertising&rdquo; and &ldquo;Your Rights
        and Choices&rdquo; sections below.
        <br />
        <br />
        <h2>C. Information from Other Sources.</h2>
        <br />
        <br />
        We also collect information from other sources. The categories of
        sources from which we collect information include social networks with
        which you interact, and publicly-available sources, including data in
        the public domain.
        <br />
        <br />
        <h1>3. Use of Information.</h1>
        <br />
        <br />
        We collect and use information for business and commercial purposes in
        accordance with the practices described in this Privacy Policy. Our
        business purposes for collecting and using information include:
        <br />
        <br />
        Operating and managing our Service.
        <br />
        <br />
        Performing services requested by you, such as responding to your
        comments, questions, and requests, and providing customer service.
        <br />
        <br />
        Sending you technical notices, updates, security alerts, information
        regarding changes to our policies, and support and administrative
        messages.
        <br />
        <br />
        Preventing and addressing fraud, breach of policies or terms, and
        threats or harm.
        <br />
        <br />
        Monitoring and analysing trends, usage, and activities.
        <br />
        <br />
        Improving the Service and other MiniMap websites, apps, marketing
        efforts, products and services.
        <br />
        <br />
        Developing and sending advertising, direct marketing, and communications
        about our and other entities&rsquo; products, offers, promotions,
        events, and services.
        <br />
        <br />
        Creating promotional material, including verifying your eligibility,
        delivering prizes in connection with your entries, or other content to
        be distributed via other media channels.
        <br />
        <br />
        Fulfilling any other purpose at your direction.
        <br />
        <br />
        With notice to you and your consent.
        <br />
        <br />
        Notwithstanding the above, we may use information that does not identify
        you (including information that has been aggregated or de-identified)
        for any purpose except as prohibited by applicable law. For information
        on your rights and choices regarding how we use information about you,
        please see the &ldquo;Your Rights and Choices&rdquo; section below.
        <br />
        <br />
        <h1>4. Sharing of Information.</h1>
        <br />
        <br />
        We share information we collect in accordance with the practices
        described in this Privacy Policy. The categories of parties with whom we
        share information include:
        <br />
        <br />
        &middot; Service Providers. We share information with service providers
        that process information on our behalf. Service providers assist us with
        services such as data analytics, marketing and advertising, website
        hosting, and technical support. We contractually prohibit our service
        providers from retaining, using, or disclosing information about you for
        any purpose other than performing the services for us, although we may
        permit them to use information that does not identify you (including
        information that has been aggregated or de-identified) for any purpose
        except as prohibited by applicable law.
        <br />
        <br />
        &middot; Vendors and Other Parties. We share information with vendors
        and other parties for analytics and advertising related purposes. These
        parties may act as our service providers, or in certain contexts,
        independently decide how to process your information. For more
        information on advertising and analytics, see the &ldquo;Analytics and
        Advertising&rdquo; section below.
        <br />
        <br />
        &middot; Affiliates. We share information with our affiliates and
        related entities, including where they act as our service providers or
        for their own internal purposes.
        <br />
        <br />
        &middot; Partners. We share information with our partners in connection
        with offering co-branded services, selling or distributing our products,
        or engaging in joint marketing activities.
        <br />
        <br />
        &middot; Promotions. Our promotions may be jointly sponsored or offered
        by other parties. When you voluntarily enter a promotion, we share
        information as set out in the official rules that govern the promotion
        as well as for administrative purposes and as required by law (e.g., on
        a winners list). By entering a promotion, you agree to the official
        rules that govern that promotion, and may, except where prohibited by
        applicable law, allow the sponsor and/or other entities to use your
        name, voice and/or likeness in advertising or marketing materials.
        <br />
        <br />
        &middot; Public Forums. We share information you make public through the
        Service, such as information in your profile or that you post on public
        boards. Please think carefully before making information public as you
        are solely responsible for any information you make public. Once you
        have posted information, you may not be able to edit or delete such
        information, subject to additional rights set out in the &ldquo;Your
        Rights and Choices&rdquo; section below.
        <br />
        <br />
        &middot; Merger or Acquisition. We share information in connection with,
        or during negotiations of, any proposed or actual merger, purchase, sale
        or any other type of acquisition or business combination of all or any
        portion of our assets, or transfer of all or a portion of our business
        to another business.
        <br />
        <br />
        &middot; Security and Compelled Disclosure. We share information to
        comply with the law or other legal process, and where required, in
        response to lawful requests by public authorities, including to meet
        national security or law enforcement requirements. We also share
        information to protect the rights, property, life, health, security and
        safety of us, the Service or anyone else.
        <br />
        <br />
        &middot; Facilitating Requests. We share information at your request or
        direction.
        <br />
        <br />
        &middot; Consent. We share information with notice to you and your
        consent.
        <br />
        <br />
        Notwithstanding the above, we may share information that does not
        identify you (including information that has been aggregated or
        de-identified) except as prohibited by applicable law. For information
        on your rights and choices regarding how we share information about you,
        please see the &ldquo;Your Rights and Choices&rdquo; section below.
        <br />
        <br />
        <h1>5. Other Parties.</h1>
        <br />
        <br />
        We offer parts of our Service through websites, platforms, and services
        operated or controlled by other parties. In addition, we integrate
        technologies operated or controlled by other parties into parts of our
        Service.
        <br />
        <br />
        Some examples include:
        <br />
        <br />
        &middot; Links. Our Service includes links that hyperlink or QR codes to
        websites, platforms, and other services not operated or controlled by
        us. We may get a commission if you purchase a product after clicking on
        a link to an affiliate&rsquo;s website.
        <br />
        <br />
        &middot; Liking, Sharing, and Logging-In. We may embed a pixel or SDK on
        our Service that allows you to &ldquo;like&rdquo; or &ldquo;share&rdquo;
        content on, or log-in to your account through social media. If you
        choose to engage with such integration, we may receive information from
        the social network that you have authorized to share with us. Please
        note that the social network may independently collect information about
        you through the integration.
        <br />
        <br />
        &middot; Brand Pages and Chatbots. We may offer our content through
        social media. Any information you provide to us when you engage with our
        content (such as through our brand page or via our chatbot on Facebook
        Messenger) is treated in accordance with this Privacy Policy. Also, if
        you publicly reference our Service on social media (e.g., by using a
        hashtag associated with MiniMap in a tweet or post), we may use your
        reference on or in connection with our Service.
        <br />
        <br />
        Please note that when you interact with other parties, including when
        you leave our Service, those parties may independently collect
        information about you and solicit information from you. The information
        collected and stored by those parties remains subject to their own
        policies and practices, including what information they share with us,
        your rights and choices on their services and devices, and whether they
        store information in the U.S. or elsewhere. We encourage you to
        familiarize yourself with and consult their privacy policies and terms
        of use.
        <br />
        <br />
        <h1>6. Analytics and Advertising.</h1>
        <br />
        <br />
        We use analytics services, such as Google Analytics, to help us
        understand how users access and use the Service. In addition, we work
        with agencies, advertisers, ad networks, and other technology services
        to place ads about our products and services on other websites and
        services. For example, we place ads through Google and Facebook that you
        may view on their platforms as well as on other websites and services.
        <br />
        <br />
        As part of this process, we may incorporate tracking technologies into
        our own Service (including our website and emails) as well as into our
        ads displayed on other websites and services. Some of these tracking
        technologies may track your activities across time and services for
        purposes of associating the different devices you use, and delivering
        relevant ads and/or other content to you (&ldquo;Interest-based
        Advertising&rdquo;).
        <br />
        <br />
        We also use audience matching services to reach people (or people
        similar to people) who have visited our Service or are identified in one
        or more of our databases (&ldquo;Matched Ads&rdquo;). This is done by us
        uploading a customer list to another party or incorporating a pixel from
        another party into our own Service, and the other party matching common
        factors between our data and their data or other datasets. For instance,
        we incorporate the Facebook pixel on our Service and may share your
        email address with Facebook as part of our use of Facebook Custom
        Audiences.
        <br />
        <br />
        As indicated above, vendors and other parties may act as our service
        providers, or in certain contexts, independently decide how to process
        your information. We encourage you to familiarize yourself with and
        consult their privacy policies and terms of use.
        <br />
        <br />
        For further information on the types of tracking technologies we use on
        the Service and your rights and choices regarding analytics,
        Interest-based Advertising, and Matched Ads, please see the
        &ldquo;Information Collected Automatically&rdquo; and &ldquo;Your Rights
        and Choices&rdquo; sections.
        <br />
        <br />
        <h1>7. Your Rights and Choices .</h1>
        <br />
        <br />
        <h2>A. Account Information.</h2>
        <br />
        <br />
        You may access, update, or remove certain information that you have
        provided to us through your account by visiting your profile settings.
        Please note that we will retain and use information about you as
        necessary to comply with our legal obligations, resolve disputes, and
        enforce our agreements. If you are a California resident, please see the
        additional disclosures at the end of this Privacy Policy.
        <br />
        <br />
        <h2>B. Tracking Technology Choices.</h2>
        <br />
        <br />
        &middot; Cookies and Pixels. Most browsers accept cookies by default.
        You can instruct your browser, by changing its settings, to decline or
        delete cookies. If you use multiple browsers on your device, you will
        need to instruct each browser separately. Your ability to limit cookies
        is subject to your browser settings and limitations.
        <br />
        <br />
        &middot; Do Not Track. Your browser settings may allow you to
        automatically transmit a &ldquo;Do Not Track&rdquo; signal to online
        services you visit. Note, however, there is no industry consensus as to
        what site and app operators should do with regard to these signals.
        Accordingly, unless and until the law is interpreted to require us to do
        so, we do not monitor or take action with respect to &ldquo;Do Not
        Track&rdquo; signals. For more information on &ldquo;Do Not
        Track,&rdquo; visit http://www.allaboutdnt.com.
        <br />
        <br />
        &middot; App and Location Technologies. You can stop all collection of
        information via an app by uninstalling the app. You can also reset your
        device Ad Id at any time through your device settings, which is designed
        to allow you to limit the use of information collected about you. You
        can stop all collection of precise location data through an app by
        uninstalling the app or withdrawing your consent through your device
        settings.
        <br />
        <br />
        Please be aware that if you disable or remove tracking technologies some
        parts of the Service may not function correctly.
        <br />
        <br />
        <h2>C. Analytics and Interest-Based Advertising.</h2>
        <br />
        <br />
        Google provides tools to allow you to opt out of the use of certain
        information collected by Google Analytics at
        https://tools.google.com/dlpage/gaoptout and by Google Analytics for
        Display Advertising or the Google Display Network at
        https://www.google.com/settings/ads/onweb/ .<br />
        <br />
        The companies we work with to provide you with targeted ads are required
        by us to give you the choice to opt out of receiving targeted ads. Most
        of these companies are participants of the Digital Advertising Alliance
        (&ldquo;DAA&rdquo;) and/or the Network Advertising Initiative
        (&ldquo;NAI&rdquo;). To learn more about the targeted ads provided by
        these companies, and how to opt out of receiving certain targeted ads
        from them, please visit: (i) for website targeted ads from DAA
        participants, https://www.aboutads.info/choices; (ii) for app targeted
        ads from DAA participants, https://www.aboutads.info/appchoices; and
        (iii) for targeted ads from NAI participants,
        https://www.networkadvertising.org/choices/. Opting out only means that
        the selected participants should no longer deliver certain targeted ads
        to you, but does not mean you will no longer receive any targeted
        content and/or ads (e.g., in connection with the participants&rsquo;
        other customers or from other technology services).
        <br />
        <br />
        To opt out of us using your data for Matched Ads, please contact us as
        set forth in the &ldquo;Contact Us&rdquo; section below and specify that
        you wish to opt out of matched ads. We will request that the applicable
        party not serve you matched ads based on information we provide to it.
        Alternatively, you may directly contact the applicable party to opt out.
        <br />
        <br />
        You may also limit our use of information collected from or about your
        mobile device for purposes of serving targeted ads to you by going to
        your device settings and selecting &ldquo;Limit Ad Tracking&rdquo; (for
        iOS devices) or &ldquo;Opt out of Interest-Based Ads&rdquo; (for Android
        devices).
        <br />
        <br />
        Please note that if you opt out using any of these methods, the opt out
        will only apply to the specific browser or device from which you opt
        out. We are not responsible for the effectiveness of, or compliance
        with, any opt out options or programs, or the accuracy of any other
        entities&rsquo; statements regarding their opt out options or programs.
        <br />
        <br />
        <h2>D. Communications.</h2>
        <br />
        <br />
        &middot; E-mails. You can opt-out of receiving promotional emails from
        us at any time by following the instructions as provided in emails to
        click on the unsubscribe link, or emailing us at the email address set
        out in the &ldquo;Contact Us&rdquo; section below with the word
        UNSUBSCRIBE in the subject field of the email. Please note that you
        cannot opt-out of non-promotional emails, such as those about your
        account, transactions, servicing, or our ongoing business relations.
        <br />
        <br />
        &middot; Push Notifications. If you have opted-in to receive push
        notification on your device, you can opt-out at any time by adjusting
        the permissions in your device or uninstalling our app.
        <br />
        <br />
        &middot; Text Messages. You can opt-out of receiving text messages to
        your phone number at any time by texting &ldquo;STOP&rdquo; in response
        to any text message you receive from us or contacting us as set out in
        the &ldquo;Contact Us&rdquo; section below and specifying you want to
        opt-out of text messages.
        <br />
        <br />
        Please note that your opt out is limited to the email address, device,
        and phone number used and will not affect subsequent subscriptions.
        <br />
        <br />
        <h1>8. Children.</h1>
        <br />
        <br />
        The Service is intended for general audiences, and is not directed to
        children. We do not knowingly collect personal information (as defined
        by the U.S. Children&rsquo;s Privacy Protection Act, or
        &ldquo;COPPA&rdquo;) from children. If you are a parent or guardian and
        believe we have collected personal information in violation of COPPA,
        contact us at contact@minimap.us. We will remove the personal
        information in accordance with COPPA. We do not knowingly collect or
        &ldquo;sell,&rdquo; as that term is defined under the CCPA, the personal
        information of minors under 16 years old who are California residents.
        <br />
        <br />
        <h1>9. Data Security.</h1>
        <br />
        <br />
        We implement and maintain reasonable administrative, physical, and
        technical security safeguards to help protect information about you from
        loss, theft, misuse and unauthorized access, disclosure, alteration and
        destruction. Nevertheless, transmission via the internet is not
        completely secure and we cannot guarantee the security of information
        about you.
        <br />
        <br />
        <h1>10. International Transfer.</h1>
        <br />
        <br />
        We are based in the U.S. and the information we collect is governed by
        U.S. law. If you are accessing the Service from outside of the U.S.,
        please be aware that information collected through the Service may be
        transferred to, processed, stored, and used in the U.S. and other
        jurisdictions. Data protection laws in the U.S. and other jurisdictions
        may be different from those of your country of residence. Your use of
        the Service or provision of any information therefore constitutes your
        consent to the transfer to and from, processing, usage, sharing, and
        storage of information about you in the U.S. and other jurisdictions as
        set out in this Privacy Policy.
        <br />
        <br />
        <h1>11. Changes to this Privacy Policy.</h1>
        <br />
        <br />
        We reserve the right to revise and reissue this Privacy Policy at any
        time. Any changes will be effective immediately upon posting of the
        revised Privacy Policy. Your continued use of our Service indicates your
        consent to the Privacy Policy then posted. If the changes are material,
        we may provide you additional notice to your email address.
        <br />
        <br />
        <h1>12. Contact Us.</h1>
        <br />
        <br />
        If you have any questions or comments about this Privacy Policy, our
        data practices, or our compliance with applicable law, please contact
        us:
        <br />
        <br />
        By email:
        <br />
        <br />
        contact@minimap.us
        <br />
        <br />
        By mail:
        <br />
        <br />
        Rad.r, Inc.
        <br />
        <br />
        229 Chrystie Street Apt. 519
        <br />
        <br />
        New York, NY 10002
        <br />
        <br />
        This Privacy Policy has been designed to be accessible to people with
        disabilities. If you experience any difficulties accessing the
        information here, please contact us at contact@minimap.us.
        <br />
        <br />
        <h1>13. Additional Disclosures for Nevada Residents.</h1>
        <br />
        <br />
        Nevada law (NRS 603A.340) requires each business to establish a
        designated request address where Nevada consumers may submit requests
        directing the business not to sell certain kinds of personal information
        that the business has collected or will collect about the consumer. A
        sale under Nevada law is the exchange of personal information for
        monetary consideration by the business to a third party for the third
        party to license or sell the personal information to other third
        parties. If you are a Nevada consumer and wish to submit a request
        relating to our compliance with Nevada law, please contact us as at
        contact@minimap.us.
        <br />
        <br />
        <h1>14. Additional Disclosures for California Residents.</h1>
        <br />
        <br />
        These additional disclosures apply only to California residents. The
        California Consumer Privacy Act of 2018 (&ldquo;CCPA&rdquo;) provides
        additional rights to know, delete and opt-out, and requires businesses
        collecting or disclosing personal information to provide notices and
        means to exercise rights.
        <br />
        <br />
        <h2>A. Notice of Collection.</h2>
        <br />
        <br />
        In the past 12 months, we have collected the following categories of
        personal information, as described in the CCPA:
        <br />
        <br />
        &middot; Identifiers, including name, address, email address, and online
        identifiers (such as IP address).
        <br />
        <br />
        &middot; Customer records, including phone number, billing address, and
        credit or debit card information.
        <br />
        <br />
        &middot; Characteristics of protected classifications under California
        or federal law, including gender.
        <br />
        <br />
        &middot; Commercial or transactions information, including records of
        products or services purchased, obtained, or considered.
        <br />
        <br />
        &middot; Internet activity, including browsing history, search history,
        and interactions with a website, email, application, or advertisement.
        <br />
        <br />
        &middot; Geolocation data.
        <br />
        <br />
        &middot; Photographs, including your profile picture.
        <br />
        <br />
        &middot; Employment and education information to evaluate a job
        application.
        <br />
        <br />
        &middot; Inferences drawn from the above information about your
        predicted characteristics and preferences.
        <br />
        <br />
        For further details on information we collect, including the sources
        from which we receive information, review the &ldquo;Information
        Collection&rdquo; section above. We collect and use these categories of
        personal information for the business purposes described in the
        &ldquo;Use of Information&rdquo; section above, including to manage our
        Service.
        <br />
        <br />
        Under the CCPA, &ldquo;sell&rdquo; is defined broadly, and some of our
        data sharing practices may be considered a &ldquo;sale.&rdquo;
        <br />
        <br />
        We do not generally sell information as the term &ldquo;sell&rdquo; is
        traditionally understood.
        <br />
        <br />
        To the extent &ldquo;sale&rdquo; under the CCPA is interpreted to
        include the activities set out in this Privacy Policy, such as those
        disclosed in the &ldquo;Analytics and Advertising&rdquo; section above,
        we will comply with applicable law as to such activity. We disclose the
        following categories of personal information for commercial purposes:
        identifiers, characteristics, commercial or transactions information,
        internet activity, geolocation data, and inferences drawn. Please review
        the &ldquo;Sharing of Information&rdquo; section above for further
        details about the categories of parties with whom we share information.
        <br />
        <br />
        <h2>B. Right to Know and Delete.</h2>
        <br />
        <br />
        You have the right to know certain details about our data practices in
        the past 12 months. In particular, you may request the following from
        us:
        <br />
        <br />
        &middot; The categories of personal information we have collected about
        you;
        <br />
        <br />
        &middot; The categories of sources from which the personal information
        was collected;
        <br />
        <br />
        &middot; The categories of personal information about you we disclosed
        for a business purpose or sold;
        <br />
        <br />
        &middot; The categories of third parties to whom the personal
        information was disclosed for a business purpose or sold;
        <br />
        <br />
        &middot; The business or commercial purpose for collecting or selling
        the personal information; and
        <br />
        <br />
        &middot; The specific pieces of personal information we have collected
        about you.
        <br />
        <br />
        In addition, you have the right to delete the personal information we
        have collected from you.
        <br />
        <br />
        To exercise any of these rights, please submit a request through our
        Online Form or email us at contact@minimap.us. In the request, please
        specify which right you are seeking to exercise and the scope of the
        request. We will confirm receipt of your request within 10 days. We may
        require specific information from you to help us verify your identity
        and process your request. If we are unable to verify your identity, we
        may deny your requests to know or delete.
        <br />
        <br />
        <h2>C. Right to Opt-Out.</h2>
        <br />
        <br />
        To the extent MiniMap sells your personal information as the term
        &ldquo;sell&rdquo; is defined under the CCPA, you have the right to
        opt-out of the sale of your personal information by us to third parties
        at any time. You may submit a request to opt-out by clicking Do Not Sell
        My Personal Information or emailing us at contact@minimap.us.
        <br />
        <br />
        <h2>D. Authorized Agent.</h2>
        <br />
        <br />
        You can designate an authorized agent to submit requests on your behalf.
        However, we will require written proof of the agent&rsquo;s permission
        to do so and verify your identity directly.
        <br />
        <br />
        <h2>E. Right to Non-Discrimination.</h2>
        <br />
        <br />
        You have the right not to receive discriminatory treatment by us for the
        exercise of any your rights.
        <br />
        <br />
        <h2>F. Shine the Light.</h2>
        <br />
        <br />
        Customers who are residents of California may request (i) a list of the
        categories of personal information disclosed by us to third parties
        during the immediately preceding calendar year for those third
        parties&rsquo; own direct marketing purposes; and (ii) a list of the
        categories of third parties to whom we disclosed such information. To
        exercise a request, please write us at the email or postal address set
        out in &ldquo;Contact Us&rdquo; above and specify that you are making a
        &ldquo;California Shine the Light Request.&rdquo; We may require
        additional information from you to allow us to verify your identity and
        are only required to respond to requests once during any calendar year.
        <br />
        <br />
      </span>
    </p>
  );
};

export const TermsText: React.FC = () => {
  return (
    <>
      <p>
        <span style={{ whiteSpace: "pre-wrap" }}>
          Effective Date: October 15, 2020.
          <br />
          <br />
          <h1>1. Introduction and Overview.</h1>
          <br />
          <br />
          These Terms of Use (&ldquo;Terms&rdquo;) set forth a legally binding
          agreement between you and Rad.r, Inc. d/b/a MiniMap
          (&ldquo;MiniMap&rdquo;, &ldquo;we&rdquo;, &ldquo;our&rdquo;, or
          &ldquo;us&rdquo;), and govern your use of any online service location
          that posts a link to these Terms, and all features, content, and other
          services that we own, control and make available through such online
          service location (collectively, the &ldquo;Service&rdquo;).
          <br />
          <br />
          PLEASE NOTE THAT THESE TERMS CONTAIN PROVISIONS THAT GOVERN THE
          RESOLUTION OF DISPUTES BETWEEN US AND YOU AND LIMIT OUR LIABILITY TO
          YOU (SEE AGREEMENT TO ARBITRATE DISPUTES BELOW). THESE PROVISIONS
          SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A
          LAWSUIT IN COURT AND TO HAVE A JURY HEAR YOUR CLAIMS. IT CONTAINS
          PROCEDURES FOR MANDATORY BINDING ARBITRATION AND A CLASS ACTION
          WAIVER.
          <br />
          <br />
          In some instances, both these Terms and separate terms elsewhere on
          the Service will apply to your use of the Service (&ldquo;Additional
          Terms&rdquo;). To the extent there is a conflict between these Terms
          and any applicable Additional Terms, the Additional Terms will control
          unless they expressly state otherwise.
          <br />
          <br />
          By using the Service, you agree to these Terms, and consent to our
          collection, use and disclosure practices, and other activities as
          described in our Privacy Policy. If you do not agree and consent,
          discontinue use of the Service.
          <br />
          <br />
          <h1>2. Service Use.</h1>
          <br />
          <br />
          <h2>A. Content.</h2>
          <br />
          <br />
          The Service contains: (i) materials and other items relating to
          MiniMap and its products and services, and similar items from our
          licensors and other third parties, including all layout, information,
          databases, articles, posts, text, data, files, images, scripts,
          designs, graphics, instructions, illustrations, photographs, sounds,
          pictures, videos, advertising copy, URLs, technology, software,
          interactive features, the &ldquo;look and feel&rdquo; of the Service,
          and the compilation, assembly, and arrangement of the materials of the
          Service and any and all copyrightable material; (ii) trademarks,
          logos, trade names, trade dress, service marks, and trade identities
          of various parties, including those of MiniMap; and (iii) other forms
          of intellectual property (all of the foregoing, collectively
          &ldquo;Content&rdquo;). All right, title, and interest in and to the
          Service and the Content is the property of MiniMap or our licensors or
          certain other third parties, and is protected by U.S. and
          international copyright, trademark, trade dress, patent and/or other
          intellectual property and unfair competition rights and laws to the
          fullest extent possible.
          <br />
          <br />
          <h2>B. Limited License.</h2>
          <br />
          <br />
          Subject to your strict compliance with these Terms and any applicable
          Additional Terms, MiniMap grants you a limited, non-exclusive,
          revocable, non-assignable, personal, and non-transferable license to
          download, display, view, use, and play the Content on a personal
          computer, browser, laptop, tablet, mobile phone, or other
          internet-enabled device (each, a &ldquo;Device&rdquo;) and/or print
          one copy of the Content as it is displayed to you, in each case for
          your personal, non-commercial use only. The foregoing limited license
          (i) does not give you any ownership of, or any other intellectual
          property interest in, any Content, and (ii) may be suspended or
          terminated for any reason, in MiniMap&rsquo;s sole discretion, and
          without advance notice or liability. Your unauthorized use of the
          Content may violate copyright, trademark, privacy, publicity,
          communications, and other laws, and any such use may result in your
          personal liability, including potential criminal liability.
          <br />
          <br />
          <h2>C. Accounts.</h2>
          <br />
          <br />
          Certain features of the Service require you to register an account.
          When registering an account, you agree to: (i) provide true, accurate,
          current, and complete information; (ii) maintain and update this
          information to keep it true, accurate, current, and complete; (iii)
          protect and prevent unauthorized access to your account; (iv) not
          transfer or share your account with any third party; and (v)
          immediately notify us of any suspected or actual unauthorized use of
          your account or breach of security. Please note that you are solely
          responsible for all activities that occur under your account, whether
          or not you authorized the activity, and we are not liable for any loss
          or damage to you or any third party arising from your failure to
          comply with any of the foregoing obligations.
          <br />
          <br />
          <h2>D. Restrictions.</h2>
          <br />
          <br />
          You may not use the Service unless you are at least eighteen (18)
          years old.
          <br />
          <br />
          You may not: (i) engage in any activity in connection with the Service
          or Content that is unlawful, harmful, offensive, sexually explicit,
          obscene, violent, threatening, harassing, abusive, falsely
          representative of your persona, invasive of someone else&rsquo;s
          privacy, or otherwise objectionable to MiniMap; (ii) harvest any
          information from the Service or Content; (iii) reverse engineer or
          modify the Service or Content; (iv) interfere with the proper
          operation of or any security measure used by the Service or Content;
          (v) infringe any intellectual property or other right of any third
          party; (vi) use the Service or Content in a manner that suggests an
          unauthorized association or is beyond the scope of the limited license
          granted to you; or (vii) otherwise violate these Terms or any
          applicable Additional Terms.
          <br />
          <br />
          You agree to comply with all local, state, federal, national, foreign,
          supranational, and international laws, statutes, ordinances,
          regulations, treaties, directives, and agreements that apply to your
          use of the Service and Content.
          <br />
          <br />
          <h2>E. Availability.</h2>
          <br />
          <br />
          MiniMap may suspend or terminate the availability of the Service and
          Content, in whole or in part, to any individual user or all users, for
          any reason, in MiniMap&rsquo;s sole discretion, and without advance
          notice or liability. Upon suspension or termination of your access to
          the Service, or upon notice from MiniMap, all rights granted to you
          under these Terms or any applicable Additional Terms will cease
          immediately, and you agree that you will immediately discontinue use
          of the Service and Content.
          <br />
          <br />
          <h2>F. Reservation of Rights.</h2>
          <br />
          <br />
          All rights not expressly granted to you are reserved by MiniMap and
          its licensors and other third parties. No right or license may be
          construed, under any legal theory, by implication, estoppel, industry
          custom, or otherwise. Any unauthorized use of the Content or Service
          for any purpose is prohibited.
          <br />
          <br />
          <h1>3. User Generated Content and Unsolicited Submissions.</h1>
          <br />
          <br />
          <h2>A. User Generated Content</h2>
          <br />
          <br />
          The Service permits you to create and/or make available via the
          Service material which you own, have created or otherwise have
          appropriate rights in (which we refer to as &ldquo;User Generated
          Content&rdquo; or &ldquo;UGC&rdquo;). UGC includes, for example, your
          writings and images and any other content or materials contributed by
          users to, on, or through the Services. In order to distribute UGC
          through the Service, you must first register an account with MiniMap.
          You are solely responsible for your UGC and the consequences of
          posting or publishing UGC through the Service.
          <br />
          <br />
          By posting or publishing UGC on or through the Service, you affirm,
          represent, and warrant that:
          <br />
          <br />- You are the creator of or otherwise own the rights in the UGC
          that you make available to or through the Services, or, for any UGC
          that is owned by a third party, you have the express authorization of
          such third party to make such UGC available through the Service;
          <br />
          <br />- No element of UGC, nor the exploitation of such UGC through
          the Service, infringes the intellectual property rights or privacy or
          any other rights of anyone else or is illegal or breaches the Terms;
          <br />
          <br />- You waive and agree not to assert any moral rights or similar
          rights you may have in UGC;
          <br />
          <br />- You are solely responsible for your UGC, and acknowledge that
          MiniMap does not pre-screen any UGC and does not endorse or approve of
          any UGC that you or other users may contribute to the Services;
          <br />
          <br />- You shall not in any way claim or suggest that any UGC is
          endorsed or supported by MiniMap; and
          <br />
          <br />- Your use of the Service, including your UGC, complies with all
          applicable laws and legislation and is not harmful, offensive,
          defamatory, sexually explicit, obscene, violent, threatening,
          harassing, abusive, falsely representative of your persona, invasive
          of someone else&rsquo;s privacy, illegal or likely to cause any
          reputational loss or embarrassment to MiniMap.
          <br />
          <br />
          Your use of the Service is at your own risk. We are under no
          obligation to edit or control UGC that you or other users post or
          publish, and will not be in any way responsible or liable for UGC. You
          understand that when using the Services you may be exposed to UGC from
          a variety of sources and acknowledge that UGC may be inaccurate,
          offensive, indecent or objectionable. You agree to waive, and do
          hereby waive, any legal or equitable rights or remedies you have or
          may have against MiniMap with respect to UGC. We expressly disclaim
          any and all liability in connection with UGC.
          <br />
          <br />
          Nevertheless, if MiniMap believes that your UGC breaches any of the
          terms and conditions set forth herein, then we may remove, block,
          edit, move or disable such UGC, in our sole discretion, and to take
          any other steps which we consider appropriate including termination of
          your Account. If notified by a user or content owner that UGC
          allegedly does not conform to the Terms herein, we may investigate the
          allegation and determine, in our sole discretion, whether to remove
          the UGC, which we reserve the right to do at any time and without
          notice. For clarity, MiniMap does not permit copyright-infringement
          activities on the Service.
          <br />
          <br />
          <h2>B. Your License Grant to Your User Generated Content.</h2>
          <br />
          <br />
          By making UGC available through the Service, you are directing us to
          store your UGC as we choose. You hereby grant MiniMap a non-exclusive,
          limited, worldwide, revocable, royalty-free license to use your UGC
          provided through the Service for any purpose as permitted by law,
          including as necessary in order for us to enable your use of the
          Service, to provide you and/or other users with access to the Service,
          including the aforementioned hosting services, and to undertake any of
          the tasks set forth in these Terms, including the creation and
          distribution of advertising or other promotional material.
          <br />
          <br />
          The licenses granted in this section are granted separately with
          respect to each item of UGC that you make available through the
          Service. Licenses with respect to any images or text within your
          account, will (subject to the Terms herein) terminate automatically
          when you remove such UGC from your Account.
          <br />
          <br />
          <h2>C. Unsolicited Submissions</h2>
          <br />
          <br />
          When you submit any unsolicited ideas, feedback, opinions, techniques,
          images, sounds, videos, or other content to us through or relating to
          the Service (&ldquo;Submissions&rdquo;), you grant us a non-exclusive,
          unrestricted, unconditional, unlimited, worldwide, irrevocable,
          perpetual, transferable and cost-free right and license to host,
          store, use, display, reproduce, modify, adapt, edit, combine with
          other materials, publish, distribute, create derivative works from,
          promote, exhibit, broadcast, syndicate, sublicense (including, without
          limitation, to third party media channels, platforms, and
          distributors), publicly perform, publicly display, and otherwise use
          and exploit in any manner whatsoever, or grant third parties the right
          to do any of the foregoing, all or any portion of your Submissions,
          for any purpose whatsoever in all formats, on or through any means or
          medium now known or hereafter developed, and with any technology or
          devices now known or hereafter developed, and to advertise, market,
          and promote the same. You further irrevocably grant us the right, but
          not the obligation, to use your name in connection with your
          Submissions. You also agree to waive any right of approval for our use
          of the rights granted herein and agree to waive any moral rights that
          you may have in any Submissions, even if it is altered or changed in a
          manner not agreeable to you. To the extent not waivable, you
          irrevocably agree not to exercise such rights in a manner that
          interferes with any exercise of the granted rights.
          <br />
          <br />
          You understand that you will not receive any fees, sums,
          consideration, or remuneration for any of the rights granted in this
          Section. Our receipt of your Submissions is not an admission of their
          novelty, priority, or originality, and it does not impair our right to
          existing or future intellectual property rights relating to your
          Submissions.
          <br />
          <br />
          You represent and warrant that you own or have the necessary rights,
          licenses, consents, and permissions to grant us the rights granted in
          this Section. You alone, though, retain whatever legally cognizable
          right, title, and interest that you have in your Submission and remain
          responsible for them.
          <br />
          <br />
          <h1>4. Copyright Infringement.</h1>
          <br />
          <br />
          <h2>A. DMCA Notification.</h2>
          <br />
          <br />
          MiniMap responds to copyright notifications submitted under the
          Digital Millennium Copyright Act, 17 U.S.C. &sect; 512
          (&ldquo;DMCA&rdquo;). To submit a notice of claimed copyright
          infringement under U.S. law, provide our designated agent with the
          following written information:
          <br />
          <br />
          &middot; A physical or electronic signature of the copyright owner or
          a person authorized to act on his or her behalf;
          <br />
          <br />
          &middot; Identification of the copyrighted work claimed to have been
          infringed;
          <br />
          <br />
          &middot; Identification of the infringing material and information
          reasonably sufficient to permit us to locate that material;
          <br />
          <br />
          &middot; Your contact information, including your address, telephone
          number, and an e-mail address;
          <br />
          <br />
          &middot; A statement that you have a good faith belief that the use of
          the material in the manner asserted is not authorized by the copyright
          owner, its agent, or the law; and
          <br />
          <br />
          &middot; A statement that the information in the notification is
          accurate, and, under penalty of perjury, that you are authorized to
          act on behalf of the copyright owner.
          <br />
          <br />
          Our designated agent is:
          <br />
          <br />
          Samuel Benjamin Booke Millner
          <br />
          <br />
          28 Liberty St 35th Floor
          <br />
          <br />
          New York, NY 10005
          <br />
          <br />
          Contact@minimap.us
          <br />
          <br />
          You can obtain further information from the Copyright Office&rsquo;s
          online directory at www.dmca.copyright.gov/osp.
          <br />
          <br />
          We will respond to notifications of claimed copyright infringement in
          accordance with the DMCA.
          <br />
          <br />
          <h2>B. Counter Notification.</h2>
          <br />
          <br />
          If you believe that your material has been removed in error in
          response to a copyright notification, you may submit a counter
          notification to our designated agent with the following written
          information:
          <br />
          <br />
          &middot; A physical or electronic signature;
          <br />
          <br />
          &middot; Identification of the material that has been removed or to
          which access has been disabled and the location at which the material
          appeared before it was removed or access to it was disabled;
          <br />
          <br />
          &middot; A statement under penalty of perjury that you have a good
          faith belief that the material was removed or disabled as a result of
          mistake or misidentification of the material to be removed or
          disabled; and
          <br />
          <br />
          &middot; Your name, address, and telephone number, and a statement
          that you consent to the jurisdiction of the Federal District Court for
          the judicial district in which the address is located, or if your
          address is outside of the U.S., for any judicial district in which
          MiniMap may be found, and that you will accept service of process from
          the person who provided notification under subsection (c)(1)(C) or an
          agent of such person.
          <br />
          <br />
          We will respond to counter notifications in accordance with the DMCA.
          <br />
          <br />
          <h1>5. Customer Support.</h1>
          <br />
          <br />
          If you have any questions or comments, please send an e-mail to us
          here. You acknowledge that the provision of support is at
          MiniMap&rsquo;s sole discretion and that we have no obligation to
          provide you with customer support of any kind. All legal notices to us
          must be mailed to: support@minimap.us When you communicate with us
          electronically, you consent to receive communications from us
          electronically. You agree that all agreements, notices, disclosures,
          and other communications that we provide to you electronically satisfy
          any legal requirement that such communications be in writing.
          <br />
          <br />
          <h1>6. Third Party Services.</h1>
          <br />
          <br />
          Our Service contains content from and hyperlinks to websites,
          locations, platforms, and services operated and owned by third parties
          (&ldquo;Third Party Services&rdquo;). We may also integrate third
          party technologies into our Service and host our content on Third
          Party Services. These Third Party Services are not owned, controlled,
          or operated by us, and you acknowledge and agree that we are not
          responsible or liable for the information, content, products,
          technologies, or services on or available from such Third Party
          Services, or for the results to be obtained from using them. If you
          choose to access, transact with, or otherwise interact with any such
          Third Party Services, you do so at your own risk. For more information
          on Third Party Services, see our Privacy Policy.
          <br />
          <br />
          <h1>7. Service Features.</h1>
          <br />
          <br />
          <h2> A. Wireless Device Features.</h2>
          <br />
          <br />
          The Service may offer features that are available to you via your
          wireless Device including the ability to access the Service&rsquo;s
          features and upload content to the Service. By using the Service, you
          agree that MiniMap may collect information as described in our Privacy
          Policy, which includes information related to your use of the Services
          via your wireless Device, and that MiniMap may change, alter, or
          modify the settings or configurations on your Device in order to allow
          for or optimize your use of the Service. Data rates and other carrier
          fees may apply.
          <br />
          <br />
          <h2>B. Location-Based Features.</h2>
          <br />
          <br />
          If you have location-based features on your wireless Device, you
          acknowledge that your Device location will be tracked and shared
          consistent with the Privacy Policy. You can terminate location
          tracking by us by adjusting the permissions in your Device or
          uninstalling our app. Location-based features are used at your own
          risk and location data may not be accurate.
          <br />
          <br />
          <h2>C. Communications.</h2>
          <br />
          <br />
          (i) E-mails: You can opt-out of receiving certain promotional e-mails
          from us at any time by following the instructions as provided in
          e-mails to click on the unsubscribe link, or contacting us here with
          the word UNSUBSCRIBE in the subject field of the e-mail. Your opt-out
          will not affect non-promotional e-mails, such as those about your
          account, transactions, servicing, or MiniMap&rsquo;s ongoing business
          relations.
          <br />
          <br />
          (ii) Push Notifications: You can opt-out of receiving push
          notifications from us at any time by adjusting the permissions in your
          Device or uninstalling our app.
          <br />
          <br />
          Please note that any opt-out by you is limited to the e-mail address,
          device, or phone number used and will not affect subsequent
          subscriptions.
          <br />
          <br />
          <h1>8. Agreement to Arbitrate Disputes and Choice of Law.</h1>
          <br />
          <br />
          PLEASE READ THIS SECTION CAREFULLY &ndash; IT MAY SIGNIFICANTLY AFFECT
          YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT AND
          TO HAVE A JURY HEAR YOUR CLAIMS. IT CONTAINS PROCEDURES FOR MANDATORY
          BINDING ARBITRATION AND A CLASS ACTION WAIVER.
          <br />
          <br />
          <h2>A. We Both Agree to Arbitrate.</h2>
          <br />
          <br />
          You and MiniMap agree to resolve any claims relating to these Terms
          through final and binding arbitration, except to the extent you have
          in any manner violated or threatened to violate MiniMap&rsquo;s
          intellectual property rights (for example, trademark, trade secret,
          copyright, or patent rights). Under such circumstances MiniMap may
          bring a lawsuit solely for injunctive relief to stop unauthorized use
          or abuse of the Service, or intellectual property infringement (for
          example, trademark, trade secret, copyright, or patent rights) without
          first engaging in arbitration or the informal dispute-resolution
          process described herein.
          <br />
          <br />
          <h2>B. What is Arbitration.</h2>
          <br />
          <br />
          Arbitration is more informal than a lawsuit in court and seeks to
          resolve disputes more quickly. Instead of a judge or a jury, the case
          will be decided by a neutral arbitrator who has the power to award the
          same damages and relief that a court can. If any provision of this
          arbitration agreement is found unenforceable, the unenforceable
          provision shall be severed, and the remaining arbitration terms shall
          be enforced.
          <br />
          <br />
          <h2>C. Arbitration Procedures.</h2>
          <br />
          <br />
          The Federal Arbitration Act governs the interpretation and enforcement
          of this dispute resolution provision. Arbitration shall be initiated
          through JAMS. Any dispute, controversy, or claim arising out of or
          relating to these Terms shall be referred to and finally determined by
          arbitration in accordance with the JAMS Streamlined Arbitration Rules
          and Procedures in front of one arbitrator. If there is a conflict
          between JAMS Rules and the rules set forth in these Terms, the rules
          set forth in this Terms will govern. The JAMS Rules and instructions
          for how to initiate an arbitration are available from JAMS at
          http://www.jamsadr.com or 1-800-352-5267. To initiate arbitration, you
          or MiniMap must do the following things:
          <br />
          <br />
          (1) Write a demand for Arbitration. The demand must include a
          description of the Claim and the amount of damages sought to be
          recovered. You can find a copy of a demand for Arbitration at
          www.jamsadr.com.
          <br />
          <br />
          (2) Send three copies of the demand for Arbitration, plus the
          appropriate filing fee to: JAMS to your local JAMS office or to JAMS,
          Two Embarcadero Center, Suite 1500, San Francisco, CA 94111.
          <br />
          <br />
          (3) Send one copy of the demand for Arbitration to the other party.
          <br />
          <br />
          Payment of all filing, administration and arbitrator fees will be
          governed by the JAMS Rules. The arbitration hearing may be by
          telephone or in-person. Disputes may also be resolved by submission of
          documents and without in-person or telephonic hearings as provided by
          the Rules. To the extent necessary, the locale of the hearing will be
          the county of the consumer&rsquo;s home address or, at the
          consumer&rsquo;s election, such other locale as mutually agreed to by
          the parties, or as determined by the arbitrator. Each party will bear
          their own costs of arbitration unless the arbitrator directs that
          bearing such costs would be an undue burden and in that case, we will
          pay for your portion of the arbitration administrative costs (but not
          your attorneys&rsquo; fees). Arbitration under this agreement shall be
          held under New York law without regard to its conflict of laws
          provisions. The arbitration may award on an individual basis the same
          damages and relief as a court (including injunctive relief). Any
          judgment on the award rendered by the arbitrator may be entered in any
          court of competent jurisdiction.
          <br />
          <br />
          <h2>D. Authority of Arbitrator.</h2>
          <br />
          <br />
          The arbitrator will decide the rights and liabilities, if any, of you
          and MiniMap, and the dispute will not be consolidated with any other
          matters or joined with any other cases or parties. The arbitrator
          shall have the authority to grant motions dispositive of all or part
          of any claim. The arbitrator shall have the authority to award
          monetary damages and to grant any non-monetary remedy or relief
          available to an individual under applicable law, the Arbitration
          Rules, and the Terms. The arbitrator shall issue a written award and
          statement of decision describing the essential findings and
          conclusions on which the award is based, including the calculation of
          any damages awarded. The arbitrator has the same authority to award
          relief on an individual basis that a judge in a court of law would
          have. The award of the arbitrator is final and binding upon you and
          MiniMap.
          <br />
          <br />
          <h2>E. No Class Actions.</h2>
          <br />
          <br />
          You may only resolve disputes with us on an individual basis, and may
          not bring a claim as a plaintiff or a class member in a class,
          consolidated, or representative action. Class arbitrations, class
          actions, private attorney general actions, and consolidation with
          other arbitrations are not allowed.
          <br />
          <br />
          <h2>F. Representative PAGA Waiver.</h2>
          <br />
          <br />
          Notwithstanding any other provision of these Terms or the Agreement to
          Arbitrate, to the fullest extent permitted by law: (1) you and MiniMap
          expressly intend and agree not to assert a representative action on
          behalf of others under the Private Attorneys General Act of 2004
          (&ldquo;PAGA&rdquo;), California Labor Code &sect; 2698 et seq., in
          any court or in arbitration, and (2) for any claim brought on a
          private attorney general basis, including under the California PAGA,
          both you and MiniMap agree that any such dispute shall be resolved in
          arbitration on an individual basis only (i.e., to resolve whether you
          have personally been aggrieved or subject to any violations of law),
          and that such an action may not be used to resolve the claims or
          rights of other individuals in a single or collective proceeding
          (i.e., to resolve whether other individuals have been aggrieved or
          subject to any violations of law) (collectively, &ldquo;representative
          PAGA Waiver&rdquo;). Notwithstanding any other provision of these
          Terms, this Agreement to Arbitrate, or the Rules, disputes regarding
          the scope, applicability, enforceability, revocability or validity of
          this representative PAGA Waiver may be resolved only by a civil court
          of competent jurisdiction and not by an arbitrator. If any provision
          of this representative PAGA Waiver is found to be unenforceable or
          unlawful for any reason: (i) the unenforceable provision shall be
          severed from these Terms; (ii) severance of the unenforceable
          provision shall have no impact whatsoever on the Agreement to
          Arbitrate or the requirement that any remaining disputes be arbitrated
          on an individual basis pursuant to the Agreement to Arbitrate; and
          (iii) any such representative PAGA or other representative private
          attorneys general act claims must be litigated in a civil court of
          competent jurisdiction and not in arbitration. To the extent that
          there are any disputes to be litigated in a civil court of competent
          jurisdiction because a civil court of competent jurisdiction
          determines that the representative PAGA Waiver is unenforceable with
          respect to those disputes, the Parties agree that litigation of those
          disputes shall be stayed pending the outcome of any individual
          disputes in arbitration.
          <br />
          <br />
          <h2>G. Exceptions to Arbitration.</h2>
          <br />
          <br />
          This Agreement to Arbitrate shall not require arbitration of the
          following types of disputes: (1) small claims actions brought on an
          individual basis that are within the scope of such small claims
          court&rsquo;s jurisdiction; or (2) a representative action brought on
          behalf of others under PAGA or other private attorneys general acts,
          to the extent the representative PAGA Waiver of such action is deemed
          unenforceable by a court of competent jurisdiction under applicable
          law not preempted by the FAA.
          <br />
          <br />
          <h2>H. Waiver of Jury Trial.</h2>
          <br />
          <br />
          THE PARTIES HEREBY WAIVE THEIR CONSTITUTIONAL AND STATUTORY RIGHTS TO
          GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY, instead
          electing that all claims and disputes shall be resolved by
          arbitration. Arbitration procedures are typically more limited, more
          efficient and less costly than rules applicable in court and are
          subject to very limited review by a court. In the event any litigation
          should arise between you and MiniMap in any state or federal court in
          a suit to vacate or enforce an arbitration award or otherwise, YOU AND
          MINIMAP WAIVE ALL RIGHTS TO A JURY TRIAL, instead electing that the
          dispute be resolved by a judge. YOU ACKNOWLEDGE THAT YOU HAVE BEEN
          ADVISED THAT YOU MAY CONSULT WITH AN ATTORNEY IN DECIDING TO ACCEPT
          THIS AGREEMENT TO ARBITRATE.
          <br />
          <br />
          <h2>I. Choice of Law/Forum Selection.</h2>
          <br />
          <br />
          In any circumstances where the Agreement to Arbitrate Disputes permits
          the parties to litigate in court, these Terms shall be governed by and
          construed in accordance with the laws of the State of New York,
          excluding its conflict of law rules. You further expressly consent and
          agree to submit to the exclusive jurisdiction and venue of a court of
          competent jurisdiction located in New York, NY.
          <br />
          <br />
          <h2>J. Opt-Out of Agreement to Arbitrate.</h2>
          <br />
          <br />
          You can decline this Agreement to Arbitrate by emailing MiniMap at
          contact@minimap.us and providing the requested information as follows:
          (1) Your Name; (2) the URL of the Terms and Agreement to Arbitrate
          Disputes; (3) Your Address; (4) Your Phone Number; (5) and clear
          statement that you wish to opt out of this arbitration provision in
          the Terms. The Opt-Out Notice must be emailed no later than 30 days
          after the date you first accept the Terms by using the MiniMap
          Services.
          <br />
          <br />
          <h1>9. Disclaimer of Representations and Warranties.</h1>
          <br />
          <br />
          THE SERVICE IS PROVIDED TO YOU ON AN &quot;AS IS,&quot; &quot;AS
          AVAILABLE,&quot; AND &quot;WITH ALL FAULTS&quot; BASIS. NEITHER
          MINIMAP NOR ANY OF ITS OFFICERS, DIRECTORS, MANAGERS, EMPLOYEES OR
          AGENTS (COLLECTIVELY, THE &quot;MINIMAP PARTIES&quot;) MAKE ANY
          REPRESENTATIONS, WARRANTIES OR ENDORSEMENTS OF ANY KIND WHATSOEVER AS
          TO THE CONTENT OR OTHER SERVICES, WHETHER EXPRESS OR IMPLIED,
          STATUTORY OR OTHERWISE, OR ARISING FROM COURSE OF DEALING, COURSE OF
          PERFORMANCE OR USAGE OF TRADE, INCLUDING THE IMPLIED WARRANTIES OF
          TITLE, NON-INFRINGEMENT, MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, QUIET ENJOYMENT AND FREEDOM FROM COMPUTER VIRUS. BY ACCESSING
          OR USING THE SERVICE YOU REPRESENT AND WARRANT THAT YOUR ACTIVITIES
          ARE LAWFUL IN EVERY JURISDICTION WHERE YOU ACCESS OR USE THE SERVICE.
          <br />
          <br />
          NOTWITHSTANDING THE FOREGOING, THESE DISCLAIMERS DO NOT EXCLUDE ANY
          PRODUCT LIABILITY CLAIMS, STATUTORY CONSUMER RIGHTS, DAMAGES
          ASSOCIATED WITH PERSONAL INJURY OR RESULTING FROM MINIMAP INTENTIONAL
          MISCONDUCT, RECKLESSNESS, FRAUD, OR GROSS NEGLIGENCE.
          <br />
          <br />
          <h1>10. Limitations of Our Liability.</h1>
          <br />
          <br />
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL THE MINIMAP
          PARTIES BE LIABLE TO YOU FOR ANY LOSS, DAMAGE OR INJURY OF ANY KIND
          INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, EXEMPLARY,
          CONSEQUENTIAL OR PUNITIVE LOSSES OR DAMAGES, OR DAMAGES FOR SYSTEM
          FAILURE OR MALFUNCTION OR LOSS OF PROFITS, DATA, USE, BUSINESS OR
          GOOD-WILL, ARISING OUT OF OR IN CONNECTION WITH (A) THE SERVICE, (B)
          THESE TERMS OR (C) YOUR MISUSE OF THE SERVICE OR ANY CONTENT AVAILABLE
          ON OR THROUGH THE SERVICE. THE LIMITATIONS SET FORTH IN THIS SECTION
          SHALL APPLY REGARDLESS OF THE FORM OF ACTION, WHETHER THE ASSERTED
          LIABILITY OR DAMAGES ARE BASED ON CONTRACT, INDEMNIFICATION, TORT,
          STRICT LIABILITY, STATUTE OR ANY OTHER LEGAL OR EQUITABLE THEORY.
          <br />
          <br />
          NOTWITHSTANDING THE FOREGOING, THESE DISLAIMERS HEREIN DO NOT EXCLUDE
          ANY PRODUCT LIABILITY CLAIMS, STATUTORY CONSUMER RIGHTS, DAMAGES
          ASSOCIATED WITH PERSONAL INJURY OR RESULTING FROM MINIMAP INTENTIONAL
          MISCONDUCT, RECKLESSNESS, FRAUD, OR GROSS NEGLIGENCE.
          <br />
          <br />
          <h1>11. Indemnification.</h1>
          <br />
          <br />
          You agree to defend, indemnify and hold harmless the MiniMap Parties
          from and against any and all claims, liabilities, damages, losses,
          costs and expenses (including, reasonable attorneys&rsquo; fees and
          costs) arising out of or in connection with any of the following: (i)
          your breach or alleged breach of these Terms; (ii) your Submissions;
          (iii) your misuse of the Service; (iv) your violation of any laws,
          rules, regulations, codes, statutes, ordinances or orders of any
          governmental or quasi-governmental authorities; (v) your violation of
          the rights of any third party, including any intellectual property
          right, publicity, confidentiality, property or privacy right; (vi)
          your use of a Third Party Service; or (vii) any misrepresentation made
          by you. MiniMap reserves the right to assume, at your expense, the
          exclusive defense and control of any matter subject to indemnification
          by you. You agree to cooperate with MiniMap&rsquo;s defense of any
          claim. You will not in any event settle any claim without the prior
          written consent of MiniMap.
          <br />
          <br />
          This provision does not require you to indemnify us for any
          unconscionable commercial practice by us or for our fraud, deception,
          false promise, misrepresentation or concealment, suppression or
          omission of any material fact in connection with the Service.
          <br />
          <br />
          <h1>12. Waiver of Injunctive or other Equitable Relief.</h1>
          <br />
          <br />
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, YOU AGREE THAT YOU WILL NOT BE
          PERMITTED TO OBTAIN AN INJUNCTION OR OTHER EQUITABLE RELIEF OF ANY
          KIND, SUCH AS ANY COURT OR OTHER ACTION THAT MAY INTERFERE WITH OR
          PREVENT THE DEVELOPMENT OR EXPLOITATION OF ANY WEBSITE, APPLICATION,
          CONTENT, SUBMISSIONS, PRODUCT, SERVICE, OR INTELLECTUAL PROPERTY
          OWNED, LICENSED, USED OR CONTROLLED BY MINIMAP OR A LICENSOR OF
          MINIMAP.
          <br />
          <br />
          <h1>13. Updates to Terms.</h1>
          <br />
          <br />
          We reserve the right, at any time in our sole discretion, to modify or
          replace any part of these Terms and any applicable Additional Terms,
          without prior notice. You agree that we may notify you of any updated
          Terms and any applicable Additional Terms by posting them on the
          Service so that they are accessible via a link from the home page,
          and/or to send you an e-mail to the last e-mail address you provided
          to us. All such changes are effective immediately when we post them,
          or such later date as may be specified in the notice of updated Terms
          and any applicable Additional Terms. If you object to any such
          changes, your sole recourse is to cease using the Service.
          <br />
          <br />
          <h1>14. General Provisions.</h1>
          <br />
          <br />
          <h2>A. Consent or Approval.</h2>
          <br />
          <br />
          No MiniMap consent or approval may be deemed to have been granted by
          MiniMap without being in writing and signed by an officer of MiniMap.
          <br />
          <br />
          <h2>B. Survival.</h2>
          <br />
          <br />
          The provisions of these Terms and any applicable Additional Terms,
          which by their nature should survive termination of your use of the
          Service, including sections on Service Use (except for the limited
          license), Unsolicited Submissions, Copyright Infringement,
          Subscriptions and Products, Customer Support, Third Party Services,
          Service Features, Agreement to Arbitrate Disputes and Choice of Law,
          Disclaimer of Representations and Warranties, Limitations of Our
          Liability, Indemnification, Waiver of Injunctive or Other Equitable
          Relief, Updates to Terms, and General Provisions, will survive.
          <br />
          <br />
          <h2>C. Severability; Interpretation; Assignment.</h2>
          <br />
          <br />
          If any provision of these Terms, or any applicable Additional Terms,
          is for any reason deemed invalid, unlawful, void, or unenforceable,
          then that provision will be deemed severable from these Terms or the
          Additional Terms, and the invalidity of the provision will not affect
          the validity or enforceability of the remainder of these Terms or the
          Additional Terms. You hereby waive any applicable statutory and common
          law that may permit a contract to be construed against its drafter.
          The summaries of provisions and section headings are provided for
          convenience only and shall not limit the full Terms. MiniMap may
          assign its rights and obligations under these Terms and any applicable
          Additional Terms, in whole or in part, to any party at any time
          without any notice. These Terms and any applicable Additional Terms
          may not be assigned by you, and you may not delegate your duties under
          them, without the prior written consent of an officer of MiniMap.
          <br />
          <br />
          <h2>D. Complete Agreement; No Waiver.</h2>
          <br />
          <br />
          These Terms, and any applicable Additional Terms, reflect our complete
          agreement regarding the Service and supersede any prior agreements,
          representations, warranties, assurances or discussion related to the
          Service. Except as expressly set forth in these Terms or any
          applicable Additional Terms, (i) no failure or delay by you or MiniMap
          in exercising any of rights, powers, or remedies under will operate as
          a waiver of that or any other right, power, or remedy, and (ii) no
          waiver or modification of any term of these Terms or any applicable
          Additional Terms will be effective unless in writing and signed by the
          party against whom the waiver or modification is sought to be
          enforced.
          <br />
          <br />
          <h2>E. International Issues.</h2>
          <br />
          <br />
          MiniMap controls and operates the Service from the U.S., and MiniMap
          makes no representation that the Service is appropriate or available
          for use beyond the U.S. You agree that the United Nations Convention
          on Contracts for the International Sale of Goods does not apply to
          these Terms or to any sale of goods carried out as a result of your
          use of the Service. Software related to or made available by the
          Service may be subject to export controls of the U.S., and, except as
          authorized by law, you agree and warrant not to export or re-export
          the software to any county, or to any person, entity, or end-user
          subject to U.S. export controls or sanctions.
          <br />
          <br />
          <h2>F. Investigations; Cooperation with Law Enforcement.</h2>
          <br />
          <br />
          MiniMap reserves the right to investigate and prosecute any suspected
          breaches of these Terms or the Service. MiniMap may disclose any
          information as necessary to satisfy any law, regulation, legal process
          or governmental request.
          <br />
          <br />
          <h2>G. California Consumer Rights and Notices.</h2>
          <br />
          <br />
          Residents of California are entitled to the following specific
          consumer rights information: you may contact the Complaint Assistance
          Unit of the Division of Consumer Services of the Department of
          Consumer Affairs by mail at: 400 R St., Suite 1080, Sacramento,
          California, 95814, or by telephone at (916) 445-1254. Their website is
          located at: http://www.dca.ca.gov.
          <br />
          <br />
          <h2>H. Terms Applicable for Apple iOS.</h2>
          <br />
          <br />
          If you are using the Service through an Apple Device, the following
          terms apply:
          <br />
          <br />
          (i) To the extent that you are accessing the Service through an Apple
          Device, you acknowledge that these Terms are entered into between you
          and MiniMap and, that Apple, Inc. (&ldquo;Apple&rdquo;) is not a party
          to these Terms other than as third-party beneficiary as contemplated
          below.
          <br />
          <br />
          (ii) The license granted to you in these Terms is subject to the
          permitted Usage Rules set forth in the App Store Terms of Service
          (see: http://www.apple.com/legal/itunes/us/terms.html) and any third
          party terms of agreement applicable to the Service.
          <br />
          <br />
          (iii) You acknowledge that MiniMap, and not Apple, is responsible for
          providing the Service and Content thereof.
          <br />
          <br />
          (iv) You acknowledge that Apple has no obligation whatsoever to
          furnish any maintenance or any support services to you with respect to
          the Service.
          <br />
          <br />
          (v) To the maximum extent not prohibited by applicable law, Apple will
          have no other warranty obligation whatsoever with respect to the
          Service.
          <br />
          <br />
          (vi) Notwithstanding anything to the contrary herein, and subject to
          the terms in these Terms, you acknowledge that, solely as between
          Apple and MiniMap, MiniMap, and not Apple is responsible for
          addressing any claims you may have relating to the Service, or your
          possession and/or use thereof, including, but not limited, to: (a)
          product liability claims; (b) any claim that the Service fails to
          confirm to any applicable legal or regulatory requirement; and (c)
          claims arising under consumer protection or similar legislation.
          <br />
          <br />
          (vii) Further, you agree that if the Service, or your possession and
          use of the Service, infringes on a third party&rsquo;s intellectual
          property rights, you will not hold Apple responsible for the
          investigation, defense, settlement and discharge of any such
          intellectual property infringement claims.
          <br />
          <br />
          (viii) You acknowledge and agree that Apple, and Apple&rsquo;s
          subsidiaries, are third party beneficiaries of these Terms, and that,
          upon your acceptance of the terms and conditions of these Terms, Apple
          will have the right (and will be deemed to have accepted the right) to
          enforce these Terms against you as a third-party beneficiary thereof.
          <br />
          <br />
          (ix) When using the Service, you agree to comply with any and all
          third party terms that are applicable to any platform, website,
          technology or service that interacts with the Service.
        </span>
      </p>
      <p>
        <br />
      </p>
    </>
  );
};
