import styled from "styled-components";
import { Breakpoints, Colors, Fonts, FontSizes } from "styles/variables";

export const Container = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  background: #ffffff;

  .button-icon {
    animation: rotate 2s linear 0s infinite;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .content {
    margin: 0 20px;
    min-height: 50vh;

    .title {
      font-weight: 700;
      font-size: ${FontSizes.lg};
      font-family: ${Fonts.poppins};
      color: ${Colors.scndBlack};
      margin: 60px 0 8px;
    }

    .description {
      margin-bottom: 28px;
      font-size: ${FontSizes.rg};
      color: #828282;
      line-height: 26px;
      font-weight: 400;
    }

    > form {
      > button[type="submit"] {
        border-radius: 24px;
        background: ${Colors.cnbar};
        padding: 15px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: ${FontSizes.rg};
        font-weight: 700;
        color: #ffffff;
        transition: 0.3s;
        margin: 52px 0 15px;
        width: 178px;
        max-width: 400px;

        :hover {
          background: ${Colors.cnbar};
        }
      }
    }
  }

  @media screen and (min-width: ${Breakpoints.ipad}) and (max-width: ${Breakpoints.desktop}) {
    .content {
      margin-left: 146px;
    }
  }

  @media screen and (min-width: ${Breakpoints.desktop}) {
    .content {
      margin: 206px 150px;

      > form {
        > button[type="submit"] {
          background: ${Colors.cnbar};
          width: 204px;
          margin: 62px 0 0;
        }
      }
    }
  }
`;
