import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "pages/home";
import SignIn from "pages/signIn";
import PrivacyTerms from "pages/privacy_and_terms";
import { ToastProvider } from "hooks/ToastContext";

const appRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/terms" component={() => <PrivacyTerms page="terms" />} />
      <Route
        path="/privacy"
        component={() => <PrivacyTerms page="privacy" />}
      />
      <ToastProvider>
        <Route path="/sign-up" component={SignIn} />
      </ToastProvider>
    </Switch>
  );
};

export default appRoutes;
