import styled from "styled-components";
import { Breakpoints, FontSizes } from "styles/variables";
import { FooterProps } from "libs/navProps";

export const Container = styled.div<FooterProps>`
  padding: 27px 0 27px 20px;
  ${({ page }) => page === "sign-in" && `position: absolute; bottom: 0;`};

  .terms-privacy {
    font-size: ${FontSizes.rg};
    font-weight: 400;
    line-height: 26px;
    color: #000000;
    margin-bottom: 41px;

    > a {
      :first-child {
        margin-right: 5px;
      }

      :last-child {
        margin-left: 5px;
      }
    }
  }

  .info {
    > a {
      display: flex;
      align-items: center;

      > img {
        min-width: 42px;
        min-height: 42px;
      }

      > p {
        margin-left: 8px;
      }
    }
  }

  .icons {
    display: none;
  }

  @media screen and (min-width: ${Breakpoints.ipad}) and (max-width: ${Breakpoints.desktop}) {
    .terms-privacy {
      padding-right: 10% !important;
    }
  }

  @media screen and (min-width: ${Breakpoints.desktop}) {
    padding: 38px 132px;
  }

  @media screen and (min-width: ${Breakpoints.ipad}) and (max-width: ${Breakpoints.desktop}) {
    padding: 24px 20px;
  }

  @media screen and (min-width: ${Breakpoints.ipad}) {
    display: flex;
    width: 100%;
    min-height: 0;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    /* position: absolute; */
    ${({ page }) => page === "sign-in" && `position: absolute; bottom: 0;`};

    .terms-privacy {
      margin-bottom: 0;
      padding-right: 30%;

      > a {
        :first-child {
          margin-right: 5px;
        }

        :last-child {
          margin-left: 5px;
        }
      }
    }

    .info {
      align-items: center;
      width: fit-content;

      > img {
        min-width: 42px;
        min-height: 42px;
      }

      > p {
        margin-left: 12px;
      }
    }

    .icons {
      display: flex;

      .icon-container {
        margin-right: 18px;

        .icon {
          height: 38.47px;
          width: 38.47px;
        }
      }
    }
  }

  @media screen and (max-width: ${Breakpoints.mobile}) {
    min-height: unset;
  }

  @media screen and (min-width: ${Breakpoints.mobile}) and (max-width: ${Breakpoints.ipad}) {
    .terms-privacy {
      font-size: ${FontSizes.med};
    }

    .info {
      > img {
        min-width: 64px;
        min-height: 64px;
      }

      > p {
        font-size: ${FontSizes.med};
      }
    }
  }
`;
