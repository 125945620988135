import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const clientCredentials = {
  apiKey: "AIzaSyDROV9Ewvokjg4qsBOFJyJxhDLN6klwUaY",
  authDomain: "minimap-website.firebaseapp.com",
  databaseURL: "https://minimap-website.firebaseio.com",
  projectId: "minimap-website",
  storageBucket: "minimap-website.appspot.com",
  messagingSenderId: "1019815095597",
  appId: "1:1019815095597:web:05dcef53813d2b59e037a4",
  measurementId: "G-YJ7HZYG35F",
};

export const app = initializeApp(clientCredentials);
export const db = getFirestore(app);
export const auth = getAuth(app);
