import React, { useRef, useState } from "react";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import { Form } from "@unform/web";
import { AiOutlineMail } from "react-icons/ai";
import { Icon } from "@iconify/react";
import { FormHandles } from "@unform/core";
import { useToast } from "hooks/ToastContext";
import Input from "components/Input";
import {
  doc,
  setDoc,
  serverTimestamp,
  updateDoc,
  DocumentReference,
} from "firebase/firestore";
import { signInAnonymously, signOut } from "firebase/auth";
import { db, auth } from "../../firebase/clientApp";
import { Container } from "./styles";

const collectionName =
  process.env.REACT_APP_FIREBASE_EMAILS_COLLECTION || "beta-emails-2022";

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [disabled, setDisabled] = useState(false);
  const { addToast } = useToast();

  const doesEmailExist = async (
    docRef: DocumentReference,
    email: string
  ): Promise<boolean> => {
    try {
      await updateDoc(docRef, { email });
      return true;
    } catch {
      return false;
    }
  };

  const handleSubmit = async ({ email }: { email: string }) => {
    try {
      setDisabled(true);
      const emailLowercased = email.toLowerCase();
      await signInAnonymously(auth);
      const docRef = doc(db, collectionName, emailLowercased);
      const emailExists = await doesEmailExist(docRef, emailLowercased);
      if (emailExists) {
        addToast({
          type: "info",
          title: "Your email is already registered",
        });
        setDisabled(false);
        await signOut(auth);
        return;
      }
      await setDoc(doc(db, collectionName, emailLowercased), {
        email: emailLowercased,
        timestamp: serverTimestamp(),
      });
      await signOut(auth);
      addToast({
        type: "success",
        title: "Welcome to the team!",
      });
      formRef.current?.reset();
      setDisabled(false);
    } catch (error) {
      await signOut(auth);
      addToast({
        type: "error",
        title: "Error Signing Up",
      });
      setDisabled(false);
    }
  };

  return (
    <Container>
      <Navbar page="sign-in" />

      <div className="content">
        <p className="title">Newsletter</p>
        <p className="description">
          Fill out your email here. We’ll send you biweekly recommendations of
          places to go.
        </p>
        <Form className="signIn-form" onSubmit={handleSubmit} ref={formRef}>
          <div>
            Email
            <Input
              type="email"
              required
              icon={AiOutlineMail}
              name="email"
              isModal
            />
          </div>

          <button
            style={
              disabled
                ? { opacity: 0.8, cursor: "not-allowed" }
                : { opacity: 1, cursor: "pointer" }
            }
            disabled={disabled}
            type="submit"
          >
            {disabled ? (
              <Icon
                icon="ant-design:loading-3-quarters-outlined"
                className="button-icon"
              />
            ) : (
              "Sign Up"
            )}
          </button>
        </Form>
      </div>

      <Footer page="sign-in" />
    </Container>
  );
};

export default SignIn;
