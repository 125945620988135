/* eslint-disable consistent-return */
import React from "react";
import Footer from "components/Footer";
import Navbar from "components/Navbar";
import ScrollToTopBtn from "components/BackToTopBtn";
import { TermsText, PrivacyText } from "./texts";
import { Container, Content, Title } from "./styles";

const PrivacyTerms: React.FC<{ page: string }> = ({ page }) => {
  function checkPage() {
    let text = "";

    if (page === "privacy") {
      text = "Privacy Policy";
    }

    if (page === "terms") {
      text = "Terms of Service";
    }

    return text;
  }

  function textSelection() {
    if (page === "privacy") {
      return <PrivacyText />;
    }

    if (page === "terms") {
      return <TermsText />;
    }
  }

  return (
    <Container>
      <Navbar page="terms" />

      <Content>
        <Title>{checkPage()}</Title>
        {textSelection()}
      </Content>
      <ScrollToTopBtn />
      <Footer />
    </Container>
  );
};

export default PrivacyTerms;
